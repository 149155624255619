import styled from 'styled-components';
import Content from '../component/Content';
import MainImage from '../asset/main2.jpg';

import { flow } from '../helper/keyframes';

const Wrapper = styled.div`
  padding: 0px 20px;
`;

const FirstContent = styled(Content)`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow: hidden;
  height: calc(100vh - 100px);
  max-width: unset;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    font-family: halogen, sans-serif;
    color: #0047eb;

    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      width: 100%;
    }
  }

  img#bottle {
    position: relative;
    width: 300px;
    z-index: 10;
  }

  .typo {
    z-index: 11;
    overflow: hidden;
    white-space: nowrap;

    p {
      font-size: 180px;
      animation: ${flow} 20s linear infinite;
      display: inline-block;
      color: white; // #fc6204;
      font-family: halogen, sans-serif;
      margin-right: 60px;
    }
  } */
`;

function OurStory() {
  return (
    <Wrapper>
      <FirstContent imageUrl={MainImage}>다농 이야기 헤헤</FirstContent>
      <FirstContent imageUrl={MainImage}>다농 이야기2 헤헤</FirstContent>
      <FirstContent imageUrl={MainImage}>다농 이야기3 헤헤</FirstContent>
      <FirstContent imageUrl={MainImage}>다농 이야기4 헤헤</FirstContent>
    </Wrapper>
  );
}

export default OurStory;
