import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './page/Home';
import HomeRenew from './page/HomeRenew';
import OurStory from './page/OurStory';
import Product from './page/Product';
import Header from './component/Header';
import Footer from './component/Footer';
import MobileNav from './component/MobileNav';
import ScrollToTop from './component/ScrollToTop';
import { getLocalStorage } from './helper/localStorage';
import { useNavStore } from './store/navStore';

import './config/i18n';

const Wrapper = styled.div`
  min-height: 100vh;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #0061eb;
  z-index: 9999;
`;

function LoadingPage() {
  return <LoadingWrapper></LoadingWrapper>;
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  // const [isAdult, setIsAdult] = useState(false);

  const isOpenMobileNav = useNavStore((state) => state.isOpenMobileNav);

  useEffect(() => {
    // const result = getLocalStorage('language');
    // console.log(result);
  }, []);

  return (
    <Wrapper>
      <ScrollToTop />
      {/* <LoadingPage /> */}
      <Header />
      {isOpenMobileNav ? <MobileNav /> : <></>}
      <Routes>
        {/* <Route path="/" element={<Home setIsLoading={setIsLoading} />} /> */}
        <Route path="/" element={<HomeRenew setIsLoading={setIsLoading} />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/product" element={<Product />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
      <Footer />
    </Wrapper>
  );
}

export default App;
