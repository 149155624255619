import { useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as DanongLogo } from '../asset/DanongLogo.svg';
import { bottomToTop } from '../helper/keyframes';
import { useNavStore } from '../store/navStore';
import GlobalIcon from '../asset/global-icon.png';

import Content from './Content';
import { useTranslation } from 'react-i18next';
import { language } from '../config/constants';
import { setLocalStorage } from '../helper/localStorage';

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  font-weight: bold;
  background: white;
  border-bottom: 1px solid black;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;

    & > div {
      display: flex;
      flex-direction: row;
      /* gap: 40px; */
    }

    span {
      font-size: 16px;
    }

    .left,
    .right {
      z-index: 9999;
    }

    .left {
      display: flex;
      justify-content: flex-start;
    }

    .center {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .upArrow {
        height: calc(100vh + 100px);
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        width: 4px;
        background: #fc6204;
        right: 24px;
        animation: ${bottomToTop} 2s ease-in-out forwards;
      }
    }
  }

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    text-decoration: unset;
    font-family: halogen, sans-serif;
    color: black;
    position: relative;

    span::before {
      content: '';
      position: absolute;
      left: 20px;
      bottom: 20px;
      width: 0;
      height: 1px;
      background-color: black;
      transition: width 0.25s ease-out;
    }

    &:hover {
      span::before {
        width: calc(100% - 40px);
      }
    }

    &.parentNav {
      height: 74px;
      padding: 0px 24px;
    }

    &.lngSwitch {
      height: 74px;
      margin-right: 8px;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        margin-left: 6px;
        font-family: Noto Sans KR;
        transition: opacity 0.25s ease-out;
      }

      .show {
        opacity: 1;
      }

      .hidden {
        opacity: 0.3;
      }
    }

    // 구매 버튼만 다르게 처리
    &.cta {
      background: black;
      color: white;
      transition: background-color 200ms ease-in-out;
      border: 1px solid black;
      padding: 4px 12px;
      min-height: 48px;

      &:hover {
        background: white;
        border: 1px solid black;
        color: black;

        span::before {
          width: 0;
        }
      }
    }
  }

  // 로고만 다르게 처리
  a.logo {
    &:hover {
      span::before {
        width: calc(100% - 40px);
      }
    }

    svg {
      width: 35px;
    }
  }

  .right.pc {
    display: flex;
    align-items: center;
  }

  .right.mobile {
    display: none;
  }

  .pcSubNav {
    display: flex;
    background: white;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px solid black;

    ul {
      margin: 0px;
      padding: 0px 0px 20px 0px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        width: fit-content;
        font-family: halogen, sans-serif;
        font-weight: normal;
        padding: 10px 6px;
        font-size: 16px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 6px;
          bottom: 4px;
          width: 0px;
          height: 1px;
          background-color: black;
          transition: width 0.25s ease-out;
        }
      }

      li:hover {
        cursor: pointer;

        &::before {
          width: calc(100% - 12px);
        }
      }

      li::marker {
        content: '' !important;
      }
    }

    &.menu-show {
      display: flex;
    }

    &.menu-hidden {
      display: none;
    }

    &:hover {
      display: flex;
    }
  }

  @media (max-width: 900px) {
    .header {
      padding: 0px;

      .left,
      .right {
        width: unset;
        gap: 0;
      }
    }

    a,
    button {
      font-size: 14px;
      padding: 10px 16px;
    }

    a.logo {
      padding-left: 0px;

      span::before {
        left: 0;
        width: calc(100% - 20px);
      }

      &:hover {
        span::before {
          width: calc(100% - 20px);
        }
      }
    }

    .right.pc,
    .center.pc {
      display: none;
    }

    .right.mobile {
      display: flex;

      .menuButton {
        border: 1px solid black;
      }

      .menuPage.closed {
        display: none;
      }
    }

    .pcSuvNav {
      display: none;
    }
  }

  @media (max-width: 500px) {
  }
`;

const subNavType = { DEFAULT: 'default', PRODUCT_LIST: 'productList', LANGUAGE: 'language' };

function Header() {
  const { isOpenMobileNav, toggleMobileNav } = useNavStore();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isOpenPcSubNav, setIsOpenPcSubNav] = useState(false);
  const [pcSubNavType, setPcSubNavType] = useState(subNavType.PRODUCT_LIST);

  const [isHoverOnLng, setIsHoverOnLng] = useState(false);
  const changedLngText = useMemo(() => (i18n.language === language.EN ? language.KO : language.EN), [i18n.language]);

  function handleClickPurchase() {
    window.location.href = 'https://smartstore.naver.com/danongbio';
  }

  const handleMouseOverForSub = (type = subNavType.DEFAULT) => {
    setPcSubNavType(type);
    setIsOpenPcSubNav(true);
  };

  const handleMouseOutFromSub = () => {
    setIsOpenPcSubNav(false);
  };

  const handleMouseOverLng = () => {
    setIsHoverOnLng(true);
  };

  const handleMouseOutLng = () => {
    setIsHoverOnLng(false);
  };

  const handleClickLngToggle = () => {
    setLocalStorage('language', changedLngText);
    i18n.changeLanguage(changedLngText);
  };

  const handleClickWIP = () => {
    alert('준비중입니다.');
  };

  return (
    <Wrapper>
      <Content>
        <div className="header">
          <div className="left">
            <NavLink to="/" className="logo">
              <DanongLogo />
              {/* <LogoImage className="logo" /> */}
            </NavLink>
          </div>
          <div className="right mobile">
            <button className="menuButton" onClick={toggleMobileNav}>
              {isOpenMobileNav ? t('close') : t('menu')}
            </button>
          </div>
          <div className="center pc">
            <button
              className="parentNav"
              onMouseOver={() => handleMouseOverForSub(subNavType.PRODUCT_LIST)}
              onMouseOut={() => handleMouseOutFromSub(subNavType.PRODUCT_LIST)}
            >
              <span>{t('nav.products')}</span>
            </button>
          </div>
          <div className="right pc">
            {/* <button onClick={() => handleClickContact()}>
            </button> */}
            {/* <button onClick={() => handleClickContact()}>
              <span>문의</span>
            </button> */}
            {/* <button onClick={handleClickChangeLanguage}>
              <span>EN</span>
            </button> */}
            {/* <NavLink to="our-story">
              <span>우리의 이야기</span>
            </NavLink> */}
            {/* <div className="upArrow"></div> */}
            <button className="lngSwitch" onMouseOver={handleMouseOverLng} onMouseOut={handleMouseOutLng} onClick={handleClickLngToggle}>
              <img src={GlobalIcon} alt="global-icon" />
              <>
                <span className={i18n.language === language.EN ? 'show' : 'hidden'}>EN</span>
                <span> | </span>
                <span className={i18n.language === language.KO ? 'show' : 'hidden'}>KR</span>
              </>
            </button>
            <button className="cta" onClick={handleClickPurchase}>
              <span>{t('nav.cta')}</span>
            </button>
          </div>
        </div>
      </Content>
      <div className={`pcSubNav ${isOpenPcSubNav ? 'menu-show' : 'menu-hidden'}`}>
        {pcSubNavType === subNavType.PRODUCT_LIST && (
          <ul>
            <li onClick={() => navigate('product')}>{t('subnav.gamuchiSoju')}</li>
            <li onClick={handleClickWIP}>{t('subnav.gamuchiNFS')}</li>
            <li onClick={handleClickWIP}>{t('subnav.neungHwa')}</li>
            <li onClick={handleClickWIP}>{t('subnav.drunken')}</li>
          </ul>
        )}
        {pcSubNavType === subNavType.LANGUAGE && (
          <ul>
            {/* <li onClick={handleClickWIP}>한국어</li>
            <li onClick={handleClickWIP}>English</li> */}
          </ul>
        )}
      </div>
    </Wrapper>
  );
}

export default Header;
