import styled from 'styled-components';
import Content from './Content';

const Wrapper = styled.footer`
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  font-size: 14px;

  .footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 80px 20px 100px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    span {
      display: flex;
    }

    .left {
      display: flex;
      flex-direction: column;
      word-break: keep-all;
      gap: 12px;

      span:last-child {
        margin-top: 32px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-end;
      justify-content: space-between;
      word-break: keep-all;
      gap: 90px;

      & > div {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }

      a {
        color: black;
        margin-left: 6px;
      }
    }
  }

  @media (max-width: 900px) {
    .footer {
      flex-direction: column;
      padding: 40px 0px 60px;
      gap: 18px;

      .left,
      .right {
        width: 100%;
        height: unset;
        margin: 0;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 18px;

        span:last-child {
          margin: 0;
        }
      }

      .right {
        order: 1;
        gap: 18px;
      }

      .left {
        order: 2;
      }
    }
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Content>
        <div className="footer">
          <div className="left">
            <span>충청북도 충주시 메가폴리스 1로 117</span>
            <span>
              117, Megapoliseu 1-ro, Daesowon-myeon, Chungju-si,
              <br />
              Chungcheongbuk-do, Republic of Korea
            </span>
            <span>© 농업회사법인 주식회사 다농바이오, Agricultaral Corporation DanongBio</span>
          </div>
          <div className="right">
            <span>
              Follow us on <a href="https://www.instagram.com/danongbio_kr/">instagram</a>
            </span>
            <div>
              <span>contact@danongbio.kr, 043 854 9954 </span>
            </div>
          </div>
        </div>
      </Content>
    </Wrapper>
  );
}

export default Footer;
