import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enJson from '../../locales/en.json';
import koJson from '../../locales/ko.json';

import { getLocalStorage } from '../../helper/localStorage';

function getLanguage() {
  return navigator.language || navigator.userLanguage || navigator.systemLanguage;
}

// 언어 확인
const lang = getLanguage().toLowerCase(); // 소문자로 변경
const isKor = lang.includes('ko'); // 공통으로 들어가는 "ko"로 확인

// translation catalog
const resources = {
  en: {
    translation: enJson,
  },
  ko: {
    translation: koJson,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: getLocalStorage('language') || (isKor ? 'ko' : 'en'), // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
