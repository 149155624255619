import { keyframes } from 'styled-components';

const flow = keyframes`
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

const bottomToTop = keyframes`
  0% {
    
  }

  100% {
    top: -20px;
  }
`;

export { flow, bottomToTop };
