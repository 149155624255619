import { useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import Content from '../component/Content';
import MainImage from '../asset/main3-min.jpg';
import FeatureOneImage from '../asset/feature1.jpg';
import FeatureTwoImage from '../asset/feature2_2-min.jpg';
import FeatureThreeImage from '../asset/feature3_2-min.jpg';
import BottleFrontImage from '../asset/bottle_front_2.png';
import BottleBackImage from '../asset/bottle_back.png';
import GamuchiImage from '../asset/gamuchi.png';

import MobileBreak from '../component/MobileBreak';

import { flow } from '../helper/keyframes';

const Wrapper = styled.div`
  min-height: 100vh;

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .pc {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`;

const FirstContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  height: calc(100vh - 100px);
  max-width: unset;

  .background,
  .frontBottle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-family: halogen, sans-serif;
    color: #0047eb;

    background-image: url(${({ imageUrl }) => imageUrl});
    background-color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      width: 100%;
    }
  }

  img#bottle {
    position: relative;
    width: 300px;
    z-index: 10;
  }

  .typo {
    z-index: 11;
    overflow: hidden;
    white-space: nowrap;

    p {
      font-size: 180px;
      animation: ${flow} 20s linear infinite;
      display: inline-block;
      color: #fc6204;
      font-family: halogen, sans-serif;
      margin-right: 60px;
    }
  }

  @media (max-width: 900px) {
    height: calc(100vw * 0.8 - 90px);

    .background {
      bottom: unset;
      width: 100%;
      padding-bottom: 80%;
      background-image: url(${({ imageUrl }) => imageUrl});
    }

    .typo {
      margin-bottom: 8%;

      p {
        margin: 0;
        font-size: 20vw;
      }
    }
  }

  @media (max-width: 500px) {
    height: calc(100vw - 90px);

    .background {
      padding-bottom: 100%;
    }

    .typo {
      margin-bottom: 0%;
    }
  }
`;

const SecondContent = styled(Content)`
  background: white;
  font-size: 80px;
  padding: 40px 0px 80px;
  max-width: 1200px;
  margin: 0 auto;

  span:nth-child(n + 25):nth-child(-n + 29) {
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    font-size: 40px;
    padding: 40px;
  }

  @media (max-width: 500px) {
    padding: 20px;
    font-size: 38px;
  }
`;

const ThirdContent = styled(Content)`
  padding: 40px 0px;
  display: flex;
  flex-direction: row;
  max-width: unset;

  & > div {
    width: 100%;
    height: 80vh;
    position: relative;

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }

    h3 {
      font-family: 'Halogen';
      font-style: normal;
      font-weight: 400;
      font-size: 160px;
      color: #0047eb;
      margin: -100px 0px 0px;
      margin-left: 40px;
    }

    .subTitle {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-size: 34px;
      color: #06155a;
      margin-left: 40px;
    }

    p {
      position: absolute;
      top: 35vh;
      right: 40px;
      left: 40px;
      padding: 20px 100px 20px 20px;
      color: white;
      font-size: 18px;
      line-height: 150%;
      color: white;
      word-break: keep-all;
      background: #06155a;
      /* font-family: -apple-system, monospace; */
    }

    /* &:hover {
      background-color: #fc6204;
      background-image: unset;
      transition: background-color 100ms ease-in-out;
    } */
  }

  .feature1 {
    .background {
      background-image: url(${({ images }) => images[0]});
    }
  }

  .feature2 {
    .background {
      background-image: url(${({ images }) => images[1]});
    }

    .subTitle {
      color: white;
    }
  }

  .feature3 {
    .background {
      background-image: url(${({ images }) => images[2]});
    }

    .subTitle {
      color: white;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;

    & > div {
      height: 60vh;

      p {
        top: 0;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;

    & > div {
      height: calc(100vh - 200px);

      h3 {
        font-size: 100px;
        margin-top: 20px;
      }

      .subTitle {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 500px) {
    & > div {
      height: 60vh;

      h3 {
        font-size: 60px;
        margin-left: 20px;
      }

      .subTitle {
        font-size: 38px;
        margin-left: 20px;
      }

      p {
        right: 20px;
        left: 20px;
        padding-right: 20px;
        word-break: keep-all;
      }
    }
  }
`;

const FourthContent = styled(Content)`
  display: flex;
  padding: 100px 0px;
  max-width: unset;
  position: relative;
  overflow: hidden;

  img {
    width: 300px;
    z-index: 12;
  }

  .typo {
    position: absolute;
    z-index: 11;
    overflow: hidden;
    white-space: nowrap;
    top: -40px;

    p {
      font-size: 100px;
      animation: ${flow} 12s linear infinite;
      display: inline-block;
      color: #06155a;
      font-family: halogen, sans-serif;
      margin-right: 60px;
    }
  }

  .typo2 {
    top: unset;
    bottom: -30px;
  }

  .bottle {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    z-index: 12;

    .desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 60px;
      padding: 160px 0px;

      h3,
      h4 {
        font-weight: 400;
        margin: 0;
        color: #0047eb;
      }

      h3 {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        font-size: 60px;

        span {
          font-size: 20px;
          margin: 0px 0px 12px 22px;
        }
      }

      h4 {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        margin: 16px 0px 0px 6px;

        span:first-child {
          width: 80px;
        }
      }

      ul {
        font-size: 20px;
        margin: 20px 0px 0px 6px;
        padding: 0;
        list-style: none;

        li {
          border-top: 1px solid black;
          padding: 12px 120px 12px 12px;
          transition: background-color 100ms ease-in-out;
          background-color: white;

          span:first-child {
            width: 120px;
            display: inline-block;
          }

          /* &:hover {
            background-color: #0047eb;
            color: white;
          } */
        }

        li:last-child {
          border-bottom: 1px solid black;
        }
      }

      p {
        margin: 32px 6px 0px;
        width: 700px;
        font-size: 20px;
        /* font-family: -apple-system, monospace; */
        line-height: 150%;
        word-break: keep-all;
        color: black;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .typo {
      top: -15vw;

      p {
        font-size: 20vw;
      }
    }

    .typo2 {
      top: unset;
      bottom: -17vw;
    }

    .bottle {
      flex-direction: column;

      .desc {
        box-sizing: border-box;
        margin: 0;
        padding: 20px;

        h3 {
          font-size: 38px;

          span {
            margin: 0px 0px 6px 12px;
          }
        }

        ul {
          li {
            padding: 20px;
          }
        }

        p {
          font-size: 14px;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .bottle {
      .desc {
        padding: 20px;
        margin: 24px 0px;

        ul {
          li {
            font-size: 18px;
            padding: 12px 4px;

            span:first-child {
              display: block;
              margin-bottom: 6px;
              font-size: 14px;
              width: unset;
            }
          }
        }
      }
    }
  }
`;

const FifthContent = styled(Content)`
  max-width: unset;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  box-sizing: border-box;

  font-weight: 300;
  font-size: 32px;
  line-height: 150%;
  color: white;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #06155a;

  img {
    position: absolute;
    top: 20%;
    width: 100%;
  }

  .textarea {
    padding: 20px;
    z-index: 12;
    box-sizing: border-box;
    max-width: 1200px;

    .animatedTextarea {
      span:nth-child(n + 0):nth-child(-n + 19),
      span:nth-child(n + 128):nth-child(-n + 137),
      span:nth-child(n + 299):nth-child(-n + 327) {
        font-weight: 400;
      }
      span:nth-child(n + 20):nth-child(-n + 53) {
        color: #fc6204;
      }
      span:nth-child(n + 60):nth-child(-n + 70),
      span:nth-child(n + 76):nth-child(-n + 92),
      span:nth-child(n + 143):nth-child(-n + 179) {
        font-style: italic;
      }
      span:nth-child(n + 335):nth-child(-n + 380) {
        color: #fc6204;
        font-style: italic;
      }
      span:nth-child(n + 189):nth-child(-n + 225) {
        color: #fc6204;
        font-weight: bold;
      }
    }

    .orange {
      color: #fc6204;
    }
  }

  @media (max-width: 1280px) {
    padding: 0px 40px;

    .textarea {
      max-width: unset;
    }
  }

  @media (max-width: 900px) {
    height: min-content;
    padding: 90px 20px;
    font-size: 20px;

    img {
      top: calc(50% - 80px);
    }

    .textarea {
      padding: 0px;
      line-height: 180%;
    }
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const ClickableBottle = styled.div`
  width: 300px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: 98%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 100ms ease-in-out, background-size 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-size: 100%;
  }

  @media (max-width: 900px) {
    width: 30%;
    margin: 0 auto;
    padding-bottom: 70%;
    background-size: contain;

    &:hover {
      background-size: contain;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    padding-bottom: 100%;
  }
`;

function Home({ setIsLoading }) {
  const [isShowFrontBottle, setIsShowFrontBottle] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <FirstContent imageUrl={MainImage}>
        <div className="background" />
        <div className="typo">
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
        </div>
      </FirstContent>
      <SecondContent>
        <Fade direction="up" duration={60} style={{ whiteSpace: 'unset' }} cascade>
          가무치는 쌀과 물로만 술덧을 만들어 증류한 한국 소주입니다.
        </Fade>
      </SecondContent>
      <ThirdContent images={[FeatureOneImage, FeatureTwoImage, FeatureThreeImage]}>
        <div className="feature1">
          <div className="background" />
          <Fade duration={800} direction="up" cascade triggerOnce>
            <h3>1</h3>
            <span className="subTitle">지역 특산주</span>
            <p>
              100%, <span className="highlight">충청북도 충주에서 재배된 쌀로만 만들어지는 충주의 지역 특산주 입니다. </span>개인의 성장을
              넘어 지역 사회와의 상생을 도모합니다.
            </p>
          </Fade>
        </div>
        <div className="feature2">
          <div className="background" />
          <Fade duration={800} direction="up" delay={200} cascade triggerOnce>
            <h3>2</h3>
            <span className="subTitle">
              국내 최고의
              <MobileBreak /> 상압식 증류기
            </span>
            <p>증류주의 향기성분 증진에 특화된 구조인 독일 코테사의 증류기 중 최상급의 것을 사용함으로써, 원료의 풍미를 극대화합니다.</p>
          </Fade>
        </div>
        <div className="feature3">
          <div className="background" />
          <Fade duration={800} direction="up" delay={400} cascade triggerOnce>
            <h3>3</h3>
            <span className="subTitle">긴 숙성기간</span>
            <p>옹기에서 6개월 이상의 숙성을 거쳐 소주의 거친맛을 날리고 부드러움 만을 남겼습니다.</p>
          </Fade>
        </div>
      </ThirdContent>
      <FourthContent>
        <div className="typo">
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
        </div>
        <div className="typo typo2">
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
        </div>
        <div className="bottle">
          <ClickableBottle
            imageUrl={isShowFrontBottle ? BottleFrontImage : BottleBackImage}
            onClick={() => setIsShowFrontBottle(!isShowFrontBottle)}
          />
          <div className="desc">
            <h3>
              가무치소주 <span>375ml 25도</span>
            </h3>
            <ul>
              <li>
                <span>AROMA</span>
                <span>신선한 가을사과, 배, 달콤한 쌀의 향</span>
              </li>
              <li>
                <span>TASTE</span>
                <span>크리미한 질감과 은은한 단 맛</span>
              </li>
              <li>
                <span>FINISH</span>
                <span>고소함이 머물다 옅은 바닐라로 마무리</span>
              </li>
            </ul>
            <p>
              가무치 소주는 잔에 따르는 순간부터 피어오르는 강렬한 향을 가진 소주입니다. <br />
              하지만 그 속에는 섬세하고 부드러운, 신선한 향들을 가지고 있습니다. <br />
              입에 들어오면 무게감 있고 오일리한 질감들이 퍼지며 <br />
              마지막에는 쌀에서 오는 기분좋은 달콤함이 잔잔하게 남아있게 됩니다.
              <br />
              천천히 음미하면 다양한 모습들을 만나볼 수 있는, <br />
              가무치 소주는 그런 역동적인 술입니다.
            </p>
          </div>
        </div>
      </FourthContent>
      <FifthContent>
        <img src={GamuchiImage} alt="gamuchi" />
        <div className="textarea">
          <Fade className="animatedTextarea pc" direction="up" duration={20} style={{ whiteSpace: 'unset' }} cascade triggerOnce>
            가무치는 왜, 가무치가 되었을까요? 가무치는 한국의 토종 담수어인 가물치에서 영감을 받았습니다. 가물치는 강한 생명력과 적응력,
            그리고 척박한 환경을 이겨내는 강인함을 가지고 있는 생물입니다. 저희는 가물치라는 생명체의 이러한 내재가치에 의미를 두었습니다.
            우리는 모두 다른 환경이나 새로운 환경에서 용기를 잃거나 불안해합니다. 하지만 우리는 결국은 강해질 수 있는 존재라고, 그러니 힘을
            내자고 말하고 싶었습니다. 모든 것이 빠르게 변화하는 현대 사회에서 적응하고 살아가기 위해 최선을 다하는 모든 이들의 삶을 응원하는
            마음으로, 우리의 첫번째 술을 가무치 라고 부르기로 했습니다. 가무치를 통해 세대와 시간, 그리고 사랑하는 이들을 연결해
            나가겠습니다.
          </Fade>
          <Fade className="animatedTextarea mobile" direction="up" duration={20} style={{ whiteSpace: 'unset' }} cascade triggerOnce>
            가무치는 왜, 가무치가 되었을까요? 가무치는 한국의 토종 담수어인 가물치에서 영감을 받았습니다. 가물치는 강한 생명력과 적응력,
            그리고 척박한 환경을 이겨내는 강인함을 가지고 있는 생물입니다. 저희는 가물치라는 생명체의 이러한 내재가치에 의미를 두었습니다.
            우리는 모두 다른 환경이나 새로운 환경에서 용기를 잃거나 불안해합니다. 하지만 우리는 결국은 강해질 수 있는 존재라고, 그러니 힘을
            내자고 말하고 싶었습니다. 모든 것이 빠르게 변화하는 현대 사회에서 적응하고 살아가기 위해 최선을 다하는 모든 이들의 삶을 응원하는
            마음으로, 우리의 첫번째 술을 가무치 라고 부르기로 했습니다. 가무치를 통해 세대와 시간, 그리고 사랑하는 이들을 연결해
            나가겠습니다.
          </Fade>
        </div>
      </FifthContent>
    </Wrapper>
  );
}

export default Home;
