import { useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import Content from '../component/Content';
import MainImage from '../asset/DSC_0364-min.jpg';
import HiImage from '../asset/workingman.jpg';

import { flow } from '../helper/keyframes';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  min-height: 100vh;

  .mobile {
    display: none;
  }

  .hi {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: white;
    padding: 120px 60px 60px 60px;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    .imageWrapper {
      width: 700px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: auto;
    }

    .desc {
      margin: 0px 80px;

      h3 {
        font-size: 60px;
      }

      p {
        font-size: 20px;

        hr {
          height: 1px;
          max-width: 60px;
          border: 0;
          margin: 40px 0px 40px 0px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .hi {
      .imageWrapper {
        width: 50%;
      }

      .desc {
        margin: 0px 40px 0px 60px;
      }
    }
  }

  @media (max-width: 900px) {
    .pc {
      display: none;
    }

    .mobile {
      display: block;
    }

    .hi {
      flex-direction: column;
      padding: 60px 20px;

      .imageWrapper {
        width: 70%;
      }

      .desc {
        margin: 0;

        h3 {
          font-size: 38px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
`;

const FirstContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  height: calc(100vh - 100px);
  max-width: unset;

  .background,
  .frontBottle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-family: halogen, sans-serif;
    color: #0047eb;

    background-image: url(${({ imageUrl }) => imageUrl});
    background-color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      width: 100%;
    }
  }

  img#bottle {
    position: relative;
    width: 300px;
    z-index: 10;
  }

  .typo {
    z-index: 11;
    overflow: hidden;
    white-space: nowrap;

    p {
      font-size: 180px;
      animation: ${flow} 20s linear infinite;
      display: inline-block;
      color: white;
      font-family: halogen, sans-serif;
      margin-right: 60px;
    }
  }

  @media (max-width: 900px) {
    height: calc(100vw * 0.8 - 90px);

    .background {
      bottom: unset;
      width: 100%;
      padding-bottom: 80%;
      background-image: url(${({ imageUrl }) => imageUrl});
    }

    .typo {
      margin-bottom: 8%;

      p {
        margin: 0;
        font-size: 20vw;
      }
    }
  }

  @media (max-width: 500px) {
    height: calc(100vw - 90px);

    .background {
      padding-bottom: 100%;
    }

    .typo {
      margin-bottom: 0%;
    }
  }
`;

function Home({ setIsLoading }) {
  const { t } = useTranslation();

  const [isShowFrontBottle, setIsShowFrontBottle] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <FirstContent imageUrl={MainImage}>
        <div className="background" />
        <div className="typo">
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
          <p aria-hidden="true">Connecting our lives ~ </p>
        </div>
      </FirstContent>
      <div className="hi">
        <div className="imageWrapper">
          <img src={HiImage} alt="workingman" />
        </div>
        <div className="desc">
          <h3>{t('home.title')}</h3>
          <p>
            {t('home.description1')}
            <br />
            <br />
            {t('home.description2')}
            {t('home.description3')}
            <br />
            <br />
            {t('home.description4')}
            <br />
            <br />
            {t('home.description5')}
          </p>
        </div>
      </div>
    </Wrapper>
  );
}

export default Home;
