import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0px 40px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 900px) {
    padding: 0px 20px;
  }
`;

function Content({ children, className }) {
  return <Wrapper className={className}>{children}</Wrapper>;
}

export default Content;
