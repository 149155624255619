import styled from 'styled-components';

const Wrapper = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`;

const MobileBreak = () => {
  return <Wrapper></Wrapper>;
};

export default MobileBreak;
