import { useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import Content from '../component/Content';
import FeatureOneImage from '../asset/feature1.jpg';
import FeatureTwoImage from '../asset/feature2_2-min.jpg';
import FeatureThreeImage from '../asset/feature3_2-min.jpg';
import BottleFrontImage from '../asset/bottle_front_2.png';
import BottleBackImage from '../asset/bottle_back.png';
import Bottle43BackImage from '../asset/bottle43_back.png';
import Bottle43FrontImage from '../asset/bottle43_front.png';
import GamuchiImage from '../asset/gamuchi.png';

import MobileBreak from '../component/MobileBreak';

import { useTranslation } from 'react-i18next';
import { flow } from '../helper/keyframes';

const Wrapper = styled.div`
  min-height: 100vh;

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .pc {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`;

const SecondContent = styled(Content)`
  background: white;
  font-size: 80px;
  padding: 40px 0px 80px;
  max-width: 1200px;
  margin: 0 auto;

  span:nth-child(n + 25):nth-child(-n + 29) {
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    font-size: 40px;
    padding: 40px;
  }

  @media (max-width: 500px) {
    padding: 20px;
    font-size: 38px;
  }
`;

const ThirdContent = styled(Content)`
  padding: 40px 0px;
  display: flex;
  flex-direction: row;
  max-width: unset;

  & > div {
    width: 100%;
    height: 80vh;
    position: relative;

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }

    h3 {
      font-family: 'Halogen';
      font-style: normal;
      font-weight: 400;
      font-size: 160px;
      color: #0047eb;
      margin: -100px 0px 0px;
      margin-left: 40px;
    }

    .subTitle {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-size: 34px;
      color: #06155a;
      margin-left: 40px;
    }

    p {
      position: absolute;
      top: 35vh;
      right: 40px;
      left: 40px;
      padding: 20px 100px 20px 20px;
      color: white;
      font-size: 18px;
      line-height: 150%;
      color: white;
      word-break: keep-all;
      background: #06155a;
      /* font-family: -apple-system, monospace; */
    }

    /* &:hover {
      background-color: #fc6204;
      background-image: unset;
      transition: background-color 100ms ease-in-out;
    } */
  }

  .feature1 {
    .background {
      background-image: url(${({ images }) => images[0]});
    }
  }

  .feature2 {
    .background {
      background-image: url(${({ images }) => images[1]});
    }

    .subTitle {
      color: white;
    }
  }

  .feature3 {
    .background {
      background-image: url(${({ images }) => images[2]});
    }

    .subTitle {
      color: white;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;

    & > div {
      height: 60vh;

      p {
        top: 0;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;

    & > div {
      height: calc(100vh - 200px);

      h3 {
        font-size: 100px;
        margin-top: 20px;
      }

      .subTitle {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 500px) {
    & > div {
      height: 60vh;

      h3 {
        font-size: 60px;
        margin-left: 20px;
      }

      .subTitle {
        font-size: 38px;
        margin-left: 20px;
      }

      p {
        right: 20px;
        left: 20px;
        padding-right: 20px;
        word-break: keep-all;
      }
    }
  }
`;

const FourthContent = styled(Content)`
  display: flex;
  max-width: unset;
  position: relative;
  overflow: hidden;

  img {
    width: 300px;
    z-index: 12;
  }

  .typo {
    position: absolute;
    z-index: 11;
    overflow: hidden;
    white-space: nowrap;
    top: -40px;

    p {
      font-size: 100px;
      animation: ${flow} 12s linear infinite;
      display: inline-block;
      color: #06155a;
      font-family: halogen, sans-serif;
      margin-right: 60px;
    }
  }

  .typo2 {
    top: unset;
    bottom: -30px;
  }

  .bottle {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    z-index: 12;
    padding: 100px 0px;

    .desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 60px;
      padding: 160px 0px;

      h3,
      h4 {
        font-weight: 400;
        margin: 0;
        color: #0047eb;
      }

      h3 {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        font-size: 60px;

        span {
          font-size: 20px;
          margin: 0px 0px 12px 22px;
        }
      }

      h4 {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        margin: 16px 0px 0px 6px;

        span:first-child {
          width: 80px;
        }
      }

      ul {
        font-size: 20px;
        margin: 20px 0px 0px 6px;
        padding: 0;
        list-style: none;

        li {
          border-top: 1px solid black;
          padding: 12px 120px 12px 12px;
          transition: background-color 100ms ease-in-out;
          background-color: white;

          span:first-child {
            width: 120px;
            display: inline-block;
          }

          /* &:hover {
            background-color: #0047eb;
            color: white;
          } */
        }

        li:last-child {
          border-bottom: 1px solid black;
        }
      }

      p {
        margin: 32px 6px 0px;
        width: 700px;
        font-size: 20px;
        /* font-family: -apple-system, monospace; */
        line-height: 150%;
        word-break: keep-all;
        color: black;
      }
    }

    /* 가무치 25도 */
    &.first {
      padding-bottom: 0px;

      .desc {
        padding: 160px 0px 80px;
      }
    }

    /* 가무치 43도 */
    &.second {
      padding-top: 0px;

      .desc {
        margin-right: 60px;
        margin-left: 0px;
        padding: 80px 0px 160px;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;

    .typo {
      top: -15vw;

      p {
        font-size: 20vw;
      }
    }

    .typo2 {
      top: unset;
      bottom: -17vw;
    }

    .bottle {
      flex-direction: column;

      .desc {
        box-sizing: border-box;
        margin: 0;
        order: 2;

        h3 {
          font-size: 38px;

          span {
            margin: 0px 0px 6px 12px;
          }
        }

        ul {
          li {
            padding: 20px;
          }
        }

        p {
          font-size: 14px;
          width: 100%;
        }
      }

      /* 가무치 25도 */
      &.first {
        .desc {
          padding: 32px;
        }
      }

      /* 가무치 43도 */
      &.second {
        .desc {
          margin-right: 0px;
          padding: 32px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0px;

    .bottle {
      /* 가무치 25도 */
      &.first {
        .desc {
          padding: 40px 20px;
        }
      }

      /* 가무치 43도 */
      &.second {
        padding-top: 0px;

        .desc {
          padding: 40px 20px;
          margin-right: 0px;
        }
      }

      .desc {
        padding: 20px;
        margin: 24px 0px;
        order: 2;

        ul {
          li {
            font-size: 18px;
            padding: 12px 4px;

            span:first-child {
              display: block;
              margin-bottom: 6px;
              font-size: 14px;
              width: unset;
            }
          }
        }
      }
    }
  }
`;

const FifthContent = styled(Content)`
  max-width: unset;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  box-sizing: border-box;

  font-weight: 300;
  font-size: 32px;
  line-height: 150%;
  color: white;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #06155a;

  img {
    position: absolute;
    top: 20%;
    width: 100%;
  }

  .textarea {
    padding: 20px;
    z-index: 12;
    box-sizing: border-box;
    max-width: 1200px;

    .animatedTextarea {
      span:nth-child(n + 0):nth-child(-n + 19),
      span:nth-child(n + 128):nth-child(-n + 137),
      span:nth-child(n + 299):nth-child(-n + 327) {
        font-weight: 400;
      }
      span:nth-child(n + 20):nth-child(-n + 53) {
        color: #fc6204;
      }
      span:nth-child(n + 60):nth-child(-n + 70),
      span:nth-child(n + 76):nth-child(-n + 92),
      span:nth-child(n + 143):nth-child(-n + 179) {
        font-style: italic;
      }
      span:nth-child(n + 335):nth-child(-n + 380) {
        color: #fc6204;
        font-style: italic;
      }
      span:nth-child(n + 189):nth-child(-n + 225) {
        color: #fc6204;
        font-weight: bold;
      }
    }

    .orange {
      color: #fc6204;
    }
  }

  @media (max-width: 1280px) {
    padding: 0px 40px;

    .textarea {
      max-width: unset;
    }
  }

  @media (max-width: 900px) {
    height: min-content;
    padding: 90px 20px;
    font-size: 20px;

    img {
      top: calc(50% - 80px);
    }

    .textarea {
      padding: 0px;
      line-height: 180%;
    }
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const ClickableBottle = styled.div`
  width: 300px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: 98%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 100ms ease-in-out, background-size 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-size: 105%;
  }

  @media (max-width: 900px) {
    width: 30%;
    margin: 0 auto;
    padding-bottom: 70%;
    background-size: contain;
    order: 1;

    &:hover {
      background-size: contain;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    padding-bottom: 100%;
  }
`;

function Product({ setIsLoading }) {
  const [isShowFrontBottle, setIsShowFrontBottle] = useState(true);
  const [isShowSecondFrontBottle, setIsShowSecondFrontBottle] = useState(true);

  const { t } = useTranslation();

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <SecondContent>
        <Fade direction="up" duration={60} style={{ whiteSpace: 'unset' }} cascade>
          {t('product.gamuchiSoju.title')}
        </Fade>
      </SecondContent>
      <ThirdContent images={[FeatureOneImage, FeatureTwoImage, FeatureThreeImage]}>
        <div className="feature1">
          <div className="background" />
          <Fade duration={800} direction="up" cascade triggerOnce>
            <h3>1</h3>
            <span className="subTitle">{t('product.gamuchiSoju.feature1')}</span>
            <p>{t('product.gamuchiSoju.feature1desc')}</p>
          </Fade>
        </div>
        <div className="feature2">
          <div className="background" />
          <Fade duration={800} direction="up" delay={200} cascade triggerOnce>
            <h3>2</h3>
            <span className="subTitle">{t('product.gamuchiSoju.feature2')}</span>
            <p>{t('product.gamuchiSoju.feature2desc')}</p>
          </Fade>
        </div>
        <div className="feature3">
          <div className="background" />
          <Fade duration={800} direction="up" delay={400} cascade triggerOnce>
            <h3>3</h3>
            <span className="subTitle">{t('product.gamuchiSoju.feature3')}</span>
            <p>{t('product.gamuchiSoju.feature3desc')}</p>
          </Fade>
        </div>
      </ThirdContent>
      <FourthContent>
        <div className="typo">
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
        </div>
        <div className="bottle first">
          <ClickableBottle
            imageUrl={isShowFrontBottle ? BottleFrontImage : BottleBackImage}
            onClick={() => setIsShowFrontBottle(!isShowFrontBottle)}
          />
          <div className="desc">
            <h3>
              {t('product.gamuchiSoju25.name')} <span>25% 375ml</span>
            </h3>
            <ul>
              <li>
                <span>AROMA</span>
                <span>{t('product.gamuchiSoju25.aroma')} </span>
              </li>
              <li>
                <span>TASTE</span>
                <span>{t('product.gamuchiSoju25.taste')} </span>
              </li>
              <li>
                <span>FINISH</span>
                <span>{t('product.gamuchiSoju25.finish')} </span>
              </li>
            </ul>
            <p>{t('product.gamuchiSoju25.desc')}</p>
          </div>
        </div>
      </FourthContent>
      <FourthContent>
        <div className="typo typo2">
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
          <p aria-hidden="true">GAMUCHI SOJU ~ </p>
        </div>
        <div className="bottle second">
          <div className="desc">
            <h3>
              {t('product.gamuchiSoju43.name')} <span>43% 375ml</span>
            </h3>
            <ul>
              <li>
                <span>AROMA</span>
                <span>{t('product.gamuchiSoju43.aroma')}</span>
              </li>
              <li>
                <span>TASTE</span>
                <span>{t('product.gamuchiSoju43.taste')}</span>
              </li>
              <li>
                <span>FINISH</span>
                <span>{t('product.gamuchiSoju43.finish')}</span>
              </li>
            </ul>
            <p>{t('product.gamuchiSoju43.desc')}</p>
          </div>
          <ClickableBottle
            imageUrl={isShowSecondFrontBottle ? Bottle43FrontImage : Bottle43BackImage}
            onClick={() => setIsShowSecondFrontBottle(!isShowSecondFrontBottle)}
          />
        </div>
      </FourthContent>
      <FifthContent>
        <img src={GamuchiImage} alt="gamuchi" />
        <div className="textarea">
          <Fade className="animatedTextarea pc" direction="up" duration={20} style={{ whiteSpace: 'unset' }} cascade triggerOnce>
            {t('product.story')}
          </Fade>
          <Fade className="animatedTextarea mobile" direction="up" duration={20} style={{ whiteSpace: 'unset' }} cascade triggerOnce>
            {t('product.story')}
          </Fade>
        </div>
      </FifthContent>
    </Wrapper>
  );
}

export default Product;
