import { useNavigate } from 'react-router-dom';
import { useNavStore } from '../store/navStore';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { language } from '../config/constants';

import GlobalIcon from '../asset/global-icon.png';
import styled from 'styled-components';
import { setLocalStorage } from '../helper/localStorage';
import { changeLanguage } from 'i18next';

const Wrapper = styled.div`
  position: fixed;
  height: calc(100% - 70px);
  z-index: 10000;
  overflow-y: scroll;
  inset: 71px 0px 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background: white;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  a {
    color: black;
    text-decoration: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      border-bottom: 1px solid black;
      padding: 8px 0px;
      margin: 28px 0px 22px;
    }

    & > ul > li {
      border-bottom: 0px;
      padding: 8px 0px;
      margin: 0px;
      color: #7e7e7e;

      &::marker {
        content: '- ';
      }

      &:hover {
        cursor: pointer;
      }
    }

    li::marker {
      content: '';
    }
  }

  .lngSwitch {
    margin: 20px 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      padding-top: 2px;
      margin-left: 6px;
      font-family: Noto Sans KR;
      transition: opacity 0.25s ease-out;
      color: black;
      font-family: inherit;
    }

    .show {
      opacity: 1;
    }

    .hidden {
      opacity: 0.3;
    }
  }
`;

function MobileNav() {
  const navigate = useNavigate();
  const { toggleMobileNav } = useNavStore();
  const { t, i18n } = useTranslation();

  const changedLngText = useMemo(() => (i18n.language === language.EN ? language.KO : language.EN), [i18n.language]);

  const handleClickPurchase = () => {
    window.location.href = 'https://smartstore.naver.com/danongbio';
  };

  const handleClickNavLink = (page = 'product') => {
    toggleMobileNav();
    navigate(page);
  };

  const handleClickWIP = () => {
    alert('준비중입니다.');
  };

  const handleClickLngToggle = () => {
    setLocalStorage('language', changedLngText);
    i18n.changeLanguage(changedLngText);
  };

  return (
    <Wrapper>
      <button className="lngSwitch" onClick={handleClickLngToggle}>
        <img src={GlobalIcon} alt="global-icon" />
        <>
          <span className={i18n.language === language.EN ? 'show' : 'hidden'}>EN</span>
          <span> | </span>
          <span className={i18n.language === language.KO ? 'show' : 'hidden'}>KR</span>
        </>
      </button>
      <ul>
        <li onClick={handleClickNavLink}>{t('nav.products')}</li>
        <ul>
          <li onClick={() => handleClickNavLink('product')}>{t('subnav.gamuchiSoju')}</li>
          <li onClick={handleClickWIP}>{t('subnav.gamuchiNFS')}</li>
          <li onClick={handleClickWIP}>{t('subnav.neungHwa')}</li>
          <li onClick={handleClickWIP}>{t('subnav.drunken')}</li>
        </ul>
        <li onClick={handleClickPurchase}>{t('nav.cta')}</li>
      </ul>
    </Wrapper>
  );
}

export default MobileNav;
