import { create } from 'zustand';

const useNavStore = create((set) => ({
  isOpenMobileNav: false,
  toggleMobileNav: () =>
    set((state) => {
      if (state.isOpenMobileNav) {
        document.body.classList.remove('menu-opened');
      } else {
        document.body.classList.add('menu-opened');
      }

      return { isOpenMobileNav: !state.isOpenMobileNav };
    }),
  //   increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  //   removeAllBears: () => set({ bears: 0 }),
}));

export { useNavStore };
